import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/authentication/auth.service';
import { UnionService } from '../../services/union.service';

interface Language {
  name: string;
  code: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() drawer: any;

  public languages: Language[] = [
    { code: 'GB', name: 'English' },
    { code: 'ES', name: 'Spanish' },
    { code: 'FR', name: 'French' },
    { code: 'DE', name: 'German' },
  ];
  public selectedLanguage: Language | null = null;
  constructor(
    public authService: AuthService,
    private unionService: UnionService
  ) {}

  ngOnInit(): void {
    this.setDefaultLanguage();
  }

  public setDefaultLanguage(): void {
    /* Set the default language to the union language if it exists */
    if (this.unionService.union) {
      const index = this.languages.findIndex(
        (x) => x.code === this.unionService.union?.language
      );
      if (index > -1) {
        this.selectedLanguage = this.languages[index];
      }
    }
  }

  public onLanguageChange(e): void {
    /* Emit the language change event */
    this.unionService.languageChange.emit(e.target.value);
    localStorage.setItem('language', e.target.value);
  }
}
