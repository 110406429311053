import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleSignInComponent } from './google-sign-in/google-sign-in.component';
import { MaterialModule } from '../material/material.module';
import { UserStatisticsComponent } from './user-statistics/user-statistics.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressBarColorDirective } from './progress-bar/progress-bar-color.directive';
import { PaginatorComponent } from './paginator/paginator.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OverviewComponent } from './overview/overview.component';
import { KnowledgeComponent } from './knowledge/knowledge.component';
import { ControlNavigatorComponent } from './control-navigator/control-navigator.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker'  
import { MatNativeDateModule } from '@angular/material/core'  
import { MatFormFieldModule } from '@angular/material/form-field'  

@NgModule({
  declarations: [
    GoogleSignInComponent,
    UserStatisticsComponent,
    UsersTableComponent,
    ProgressBarComponent,
    ProgressBarColorDirective,
    PaginatorComponent,
    ExpansionPanelComponent,
    SidebarComponent,
    OverviewComponent,
    KnowledgeComponent,
    ControlNavigatorComponent,
    SectionHeaderComponent,
    SidenavComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
  ],
  exports: [
    GoogleSignInComponent,
    UserStatisticsComponent,
    UsersTableComponent,
    PaginatorComponent,
    ExpansionPanelComponent,
    SidebarComponent,
    SectionHeaderComponent,
    SidenavComponent,
    ControlNavigatorComponent,
  ],
})
export class ComponentsModule {}
