<div class="container-head-users">
  <div class="title-table">
    <p>Users</p>
  </div>
  <div class="users-head-table">
    <div class="showing-users">
      <p *ngIf="total>0" class="txt-showing-users">
        Showing {{ (offset - 1) * limit + 1 }}- {{ (offset) * limit > total ? total : (offset) * limit }}
        of
        {{ total }}
      </p>
    </div>

    <form class="items-head-table">
      <div class="items-text-table">
        <mat-form-field appearance="standard">
          <mat-label>Search</mat-label>
          <input matInput type="text" [(ngModel)]="selectedSearchTerm" (input)="changeSearchTerm($event)" name="search">
          
        </mat-form-field>
      </div>

      <div class="items-text-table datepicker" [formGroup]="dateRangeForm">
        <mat-form-field appearance="standard">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker" (dateChange)="changeStartDate($event)">
            <input matStartDate formControlName="start" [ngModel]="selectedStartDate" (dateChange)="changeStartDate($event)" placeholder="Start date">
            <input matEndDate formControlName="end"  [ngModel]="selectedEndDate" (dateChange)="changeEndDate($event)" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-icon (click)="clearDateRange()" class="clear-icon">clear</mat-icon>
      </div>
      

      <div class="items-text-table">
        <mat-form-field appearance="standard">
          <mat-label>Union</mat-label>
          <mat-select (selectionChange)="changeUnion($event)" [value]="selectedUnion?.title" placeholder="Select union">
            <mat-option *ngFor="let union of unions" value="{{union.title}}"> {{union.title}} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input type="text"
                placeholder="Pick one"
                aria-label="Number"
                matInput
                [formControl]="emailFilterControl"
                [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-icon (click)="clearFilters()" class="clear-icon">close</mat-icon>
    </form>
  </div>
</div>

<div class="container-table-users" *ngIf="users.length">
  <div class="container-columns-table">
    <div class="th-table-users">
      <div class="table-th">Name</div>
      <div class="table-th table-th-mobile">Union</div>
      <div class="table-th table-th-mobile">Courses Completed</div>
      <div class="table-th table-th-mobile">Language</div>
      <div class="table-th table-th-mobile">Created at</div>
    </div>

    <div class="box-rows-table" *ngFor="let user of users">
      <div class="box-td-table td-text">{{user.name}}</div>
      <div class="title-mobile">Union</div>
      <div class="box-td-table">{{user.union}}</div>
      <div class="title-mobile">Completed courses</div>
      <div class="box-td-table">
        <app-progress-bar [completionRate]="user.progress"></app-progress-bar>
      </div>
      <div class="title-mobile">Language</div>
      <div class="box-td-table">{{user.language}}</div>
      <div class="title-mobile">Created at</div>
      <div class="box-td-table">{{user.created_at | date:'short'}}</div>
    </div>
  </div>
</div>

<div class="admin-reporting__users-table" *ngIf="!users.length">
  <div class="empty-container">
    No users found
  </div>
</div>