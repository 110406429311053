import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.scss'],
})
export class UserStatisticsComponent implements OnInit {
  @Input() completionRate: number | undefined;
  @Input() total: number | undefined;
  constructor() {}

  ngOnInit(): void {}
}
