import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/authentication/auth.service';
import { UnionService } from '../../services/union.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() drawer: any;

  constructor(
    public authService: AuthService,
    public unionService: UnionService
  ) {}

  ngOnInit(): void {}

  public onLogout(): void {
    this.authService.onLogOut();
  }
}
