import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() drawer: any;
  panelOpenState = false;

  sidenav = [
    {
      title: 'Course Outline',
      children: [
        {
          title: 'Heading 1',
          redirect: '',
        },
        {
          title: 'Heading 2',
          redirect: '',
        },
        {
          title: 'Heading 3',
          redirect: '',
        },
        {
          title: 'Heading 4',
          redirect: '',
        },
      ],
    },
    {
      title: 'Course Unit',
      children: [
        {
          title: 'Heading 1',
          redirect: '',
        },
        {
          title: 'Heading 2',
          redirect: '',
        },
        {
          title: 'Heading 3',
          redirect: '',
        },
        {
          title: 'Heading 4',
          redirect: '',
        },
      ],
    },
    {
      title: 'Survey',
      redirect: '',
    },
    {
      title: 'Results',
      redirect: '',
    },
    {
      title: 'Assessment',
      redirect: '',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
