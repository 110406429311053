<div class="introduction-to-google container">
  <div class="txt-introduction-to-google">
    <div class="box-text-introduction">
      <h1>404</h1>
      <p>
        The page that you have requested cannot be found or does not exist.
      </p>
      <a [routerLink]="['/']" class="redirect">Back to home<mat-icon>arrow_forward</mat-icon></a>
    </div>
  </div>
</div>
