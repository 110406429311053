import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { Language, Reporting, Union } from '../../types/models';
import {PageEvent, MatPaginatorModule} from '@angular/material/paginator';
import { User } from '../../types/auth';

@Component({
  selector: 'app-admin-reporting',
  templateUrl: './admin-reporting.component.html',
  styleUrls: ['./admin-reporting.component.scss'],
})
export class AdminReportingComponent implements OnInit {
  public data: Reporting | undefined;
  public unions: Union[] = [];
  public users: User[] = [];
  public limit = 15;
  public pageIndex = 0;
  public total = 0;
  public overviewCompletionRate = 0;
  public language: string | undefined;
  public email: string | undefined;
  public languages: Language[] = [];
  public union: string | undefined;
  public startDate: Date | undefined;
  public endDate: Date | undefined;
  public searchTerm: string | undefined;
  public loading = true;
  public filterApplied = false;
  public totalPages: number = 0;

  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
    this.loadReportingData();

    this.coreService.getLanguages().subscribe((languages) => {
      this.languages = languages
    });
  }

  public onLanguageChanged(language: string): void {
    this.language = language;
    this.loadReportingDataCall();
  }

  public onPageSizeChanged(pageSize: number): void {
    this.pageIndex = 0;
    this.limit = pageSize;
    this.loadReportingDataCall();
  }

  public onEmailChanged(email: string): void {
    this.email = email;
    this.loadReportingDataCall();
  }

  public onUnionChanged(union: string): void {
    this.union = union;
    this.loadReportingDataCall();
  }

  public onStartDateChanged(date: Date): void {
    this.startDate = date;
    this.loadReportingDataCall();
  }

  public onEndDateChanged(date: Date): void {
    this.endDate = date;
    this.loadReportingDataCall();
  }

  public onSearchTermChanged(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.loadReportingDataCall();
  }

  private timer: any;
  private lastCallTime: number | null = null;
  loadReportingDataCall() {
    if (this.timer) 
      clearTimeout(this.timer);

    const currentTime = Date.now();
    const timeSinceLastCall = currentTime - (this.lastCallTime || 0);

    if (timeSinceLastCall < 200) {
      this.timer = setTimeout(() => {
        this.loadReportingData();
      }, 200 - timeSinceLastCall);
    } 
    else 
      this.loadReportingData();

    this.lastCallTime = currentTime;
  }

  public loadReportingData() {
    const params: any = {
      limit: this.limit,
      offset: this.pageIndex + 1,
    };
    if (this.language) {
      params.language = this.language;
    }
    if (this.email) {
      params.email = this.email;
    }
    if (this.union) {
      params.union = this.union;
    }
    if(this.searchTerm){
      params.searchTerm = this.searchTerm
    }
    if(this.startDate){
      params.startDate = this.startDate.getTime() / 1000
    }
    if(this.endDate){
      params.endDate = this.endDate.getTime() / 1000
    }

    if(Object.keys(params).length > 2){
      this.filterApplied = true;
    }

    this.coreService.getReporting(params).subscribe(
      (data) => {
        this.users = data.records;
        this.total = data.total_count;
        this.totalPages = data.total_count / this.limit;
        this.overviewCompletionRate = data.completion_rate;
        this.coreService
          .getUnions({
            group: true,
            limit: 1000,
          })
          .subscribe(
            (data) => {
              this.unions = data.records;
              this.loading = false;
            },
            (err) => {
              this.loading = false;
            }
          );
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  public pageChanged(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.limit = event.pageSize

    this.loadReportingData();
  }
}
