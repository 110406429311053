<mat-drawer-container hasBackdrop class="drawer-container">
  <mat-drawer #drawer mode="over">
    <app-sidenav [drawer]="drawer"></app-sidenav>
  </mat-drawer>
  <app-header [drawer]="drawer"></app-header>
  <div class="container-app">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
<app-footer></app-footer>
