<div class="sidenav">
  <div class="sidenav-header">
    <img src="assets/img/Google_for_Education_logo.svg"
         class="google-logo">
  </div>

  <div class="sidenav-content">
    <div *ngFor="let sidenavItem of sidenav">
      <div *ngIf="!!sidenavItem.children && sidenavItem.children.length">
        <mat-accordion>
          <mat-expansion-panel
            class="mat-elevation-z0"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-title">
                {{sidenavItem.title}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="sidenav-children">
              <span class="sidenav-children-text"
                    *ngFor="let childrenItem of sidenavItem.children">{{childrenItem.title}}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="sidenav-title"
           *ngIf="!sidenavItem.children">{{sidenavItem.title}}</div>
    </div>
  </div>

  <div class="sidenav-footer">
    <button mat-button color="primary">Call to action</button>
    <button mat-stroked-button color="primary">Call to action</button>
  </div>
</div>
