<div class="user-statistics container">
  <div class="user-statistics__layout ">
    <div class="user-statistics__box user-statistics__box--blue">
      <div class="user-statistics__box-header">
        <div class="user-statistics__box-header-layout">
          <mat-icon> people </mat-icon>
          <span>Total users</span>
        </div>
        <div class="user-statistics__box-result">{{total}}</div>
      </div>
    </div>
    <div class="user-statistics__box user-statistics__box--green">
      <div class="user-statistics__box-header">
        <div class="user-statistics__box-header-layout">
          <mat-icon> library_add_check </mat-icon>
          <span>Users who've completed a course</span>
        </div>
        <div class="user-statistics__box-result">{{completionRate}}%</div>
      </div>
    </div>
  </div>
</div>
