import { Component, OnInit } from '@angular/core';
import { UnionService } from './services/union.service';
import { CoreService } from './services/core.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'google-workspace-training-frontend';

  rangeFormGroup = new FormGroup({  
    start: new FormControl(null, Validators.required),  
    end: new FormControl(null, Validators.required)  
  })  

  constructor(
    private unionService: UnionService,
    private coreService: CoreService
  ) {}

  ngOnInit() {}
}
