import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Language, Union } from '../../types/models';
import { MatSelectChange } from '@angular/material/select';
import { User } from '../../types/auth';
import { Subject, debounceTime } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { CoreService } from 'src/app/services/core.service';

interface LanguageOption {
  name: string;
  code: string;
}

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit {
  @Input() total = 0;
  @Input() limit = 15;
  @Input() offset = 1;
  @Input() users: User[] = [];
  @Input() email: string | undefined;
  @Input() searchTerm: string | undefined;
  @Input() language: string | undefined;
  @Input() languages: Language[] = [];
  @Input() union: string | undefined;
  @Input() unions: Union[] = [];
  @Input() startDate: Date | undefined;
  @Input() endDate: Date | undefined;
  @Output() languageChanged: EventEmitter<any> = new EventEmitter();
  @Output() emailChanged: EventEmitter<any> = new EventEmitter();
  @Output() unionChanged: EventEmitter<any> = new EventEmitter();
  @Output() searchTermChanged: EventEmitter<any> = new EventEmitter();
  @Output() startDateChanged: EventEmitter<any> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<any> = new EventEmitter();
  public selectedLanguage: LanguageOption | undefined;
  public selectedUnion: Union | undefined;
  public languageOptions: LanguageOption[] = [];
  public selectedSearchTerm: string | undefined;
  public selectedStartDate: Date | undefined;
  public selectedEndDate: Date | undefined;

  dateRangeForm: FormGroup;
  
  searchTerm$ = new Subject<string>();
  email$ = new Subject<string>();
  emailFilterControl = new FormControl('');
  emails: string[] = [];
  filteredOptions: Observable<string[]>

  constructor(private coreService: CoreService, private fb: FormBuilder) {
    this.filteredOptions = this.emailFilterControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.getEmails("");
    this.emailFilterControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.getEmails(value);
        this.emailChanged.emit(value);
      });

    this.dateRangeForm = this.fb.group({
      start: this.startDate,
      end: this.endDate,
    });
  }

  getEmails = (value) => {
    this.coreService
    .getEmails({
      search_term: value
    })
    .subscribe(
      (data) => {
        this.emails = data;
        this.emailFilterControl.setValue(this.emailFilterControl.value, { emitEvent: true });
      },
      (err) => console.error(err)
    );
  }

  public ngOnInit() {
    this.languageOptions = this.languages.map(e => ({
      code: e.code.toLowerCase(), 
      name: e.title
    }));

    if (this.language) {
      this.selectedLanguage = this.languageOptions.find(
        (lang) => lang.code === this.language
      );
    }
   
    if (this.union) {
      this.selectedUnion = this.unions.find((u) => u.title === this.union);
    }

    if (this.searchTerm) {
      this.selectedSearchTerm = this.searchTerm;
    }

    this.searchTerm$
    .pipe(
      debounceTime(500), // wait for 500ms of inactivity
      distinctUntilChanged() // only emit distinct values
    )
    .subscribe((searchTerm: string) => {
      this.selectedSearchTerm = searchTerm;
      this.searchTermChanged.emit(searchTerm);
    });
  }

  public changeLanguage(e: MatSelectChange): void {
    const value = e.value;
    const language = this.languageOptions.find((lang) => lang.code === value);
    if (language) {
      this.selectedLanguage = language;
      this.languageChanged.emit(language.code);
    }
  }

  public changeUnion(e: MatSelectChange): void {
    const value = e.value;
    const union = this.unions.find((u) => u.title === value);
    if (union) {
      this.selectedUnion = union;
      this.unionChanged.emit(union.title);
    }
  }

  public changeStartDate(event: any) {
    const date = event.value;
    if(date){
      this.selectedStartDate = date;
      this.startDateChanged.emit(date)
    }
  }

  public changeEndDate(event: any) {
    const date = event.value;
    if(date){
      this.selectedEndDate = date;
      this.endDateChanged.emit(date)
    }
  }

  public changeSearchTerm(e: Event): void {
    const inputValue = (e.target as HTMLInputElement).value;
    this.searchTerm$.next(inputValue);
  }

  public clearFilters(): void {
    this.selectedUnion = undefined;
    this.selectedLanguage = undefined;
    this.selectedSearchTerm = "";
    this.emailFilterControl.setValue("");
    this.selectedStartDate = undefined;
    this.selectedEndDate = undefined;
    this.languageChanged.emit(undefined);
    this.unionChanged.emit(undefined);
    this.searchTermChanged.emit("");
    this.emailChanged.emit("");
    this.startDateChanged.emit(undefined);
    this.endDateChanged.emit(undefined);
  }

  public clearDateRange(): void {
    this.selectedStartDate = undefined;
    this.selectedEndDate = undefined;
    this.startDateChanged.emit(undefined);
    this.endDateChanged.emit(undefined);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
  
    const filteredOptions = this.emails.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  
    return filteredOptions;
  }
}
