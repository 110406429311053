<mat-toolbar class="toolbar">
  <div class="toolbar-logo">
    <img [routerLink]="['/']" src="../../../assets/img/Google_for_Education_logo.svg" class="google-logo" alt="">
    <img *ngIf="unionService.union && unionService.union.logo_url" src="{{unionService.union.logo_url}}" class="union-image"  alt="">
  </div>
  <div class="header-icons">
    <ng-container *ngIf="authService.isLoggedIn()">
      <button mat-icon-button [matMenuTriggerFor]="dropdown">
        <mat-icon class="ic-account align-icon">account_circle</mat-icon>
      </button>
      <mat-menu #dropdown="matMenu">
        <button mat-menu-item  (click)="onLogout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Log out</span>
        </button>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>
