import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthService } from '../authentication/auth.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { User, UserToken } from '../../types/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request);
    }

    const user = this.authService.getUser();

    if (user === null || user === undefined) {
      return next.handle(request);
    }

    const accessExpired = this.authService.isAccessTokenExpired();
    const refreshExpired = this.authService.isRefreshTokenExpired();

    if (accessExpired && refreshExpired) {
      this.authService.onLogOut();
      return next.handle(request);
    }

    if (accessExpired && !refreshExpired) {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.authService.requestAccessToken().pipe(
          switchMap((authResponse: User) => {
            const userData = this.authService.getUser();
            userData.token.access = authResponse.token.access;
            this.authService.saveJWTToken(userData);
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(userData.token.refresh);
            return next.handle(this.injectToken(request));
          })
        );
      } else {
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request));
          })
        );
      }
    }

    if (!accessExpired) {
      return next.handle(this.injectToken(request));
    }

    return next.handle(request);
  }

  injectToken(request: HttpRequest<unknown>) {
    const token: UserToken | any = this.authService.getToken();
    if (!token) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token.access}`,
      },
    });
  }
}
