import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Union } from '../types/models';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root',
})
export class UnionService {
  @Output() languageChange: EventEmitter<string> = new EventEmitter();
  public union: Union | undefined;
  constructor() {}
}
