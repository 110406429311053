
<div class="container">
  <ngx-skeleton-loader count="1"  *ngIf="loading"  appearance="line" [theme]="
    {
      height: '150px',
      marginTop: '30px'
    }
"></ngx-skeleton-loader>
</div>

<div class="container">
  <div class="admin-reporting" *ngIf="!loading && (users.length || filterApplied)">
    <div class="admin-reporting__head">
      <div class="container">
        <div class="admin-reporting__head-box">
          <div class="admin-reporting__head-title">
            <div class="admin-reporting__line"></div>
            <p class="admin-reporting__head-text">Admin Reporting</p>
          </div>
          <img src="../../../assets/img/computer-red-cup.svg" alt="computer and red cup" />
        </div>
      </div>
    </div>
    <app-user-statistics [total]="total" [completionRate]="overviewCompletionRate"></app-user-statistics>
    <div class="admin-reporting__users-table container">
      <app-users-table [users]="users"
                       [total]="total"
                       [limit]="limit"
                       [offset]="(pageIndex + 1)"
                       [language]="language"
                       [languages]="languages"
                       [union]="union"
                       [unions]="unions"
                       [searchTerm]="searchTerm"
                       (searchTermChanged)="onSearchTermChanged($event)"
                       (emailChanged)="onEmailChanged($event)"
                       (languageChanged)="onLanguageChanged($event)"
                       (unionChanged)="onUnionChanged($event)"
                       (startDateChanged)="onStartDateChanged($event)"
                       (endDateChanged)="onEndDateChanged($event)"></app-users-table>
      
      <mat-paginator #paginator
        class="demo-paginator"
        (page)="pageChanged($event)"
        [length]="total"
        [pageSize]="limit"
        [pageSizeOptions]="[5, 10, 15, 25, 50, 500]"
        [pageIndex]="pageIndex"
        aria-label="Select page">
      </mat-paginator>

    </div>
  </div>
</div>


<div class="container">
  <div class="admin-reporting" style="padding: 60px;" *ngIf="!loading && users.length === 0 && !filterApplied">
    <h1 class="title" style="font-weight: bold;">403 Forbidden</h1>
    <p class="descrtiption">We could not load your data, please confirm that your account has the necessary permission to load user data on this app.</p>
  </div>
</div>


